import { useStorage } from '@vueuse/core'

const useProtocol = () => {
  const { $api } = useNuxtApp()

  const protocolDeleteDialog = useState('protocolDeleteDialog', () => false)
  const protocol = useState('protocol', () => null)
  const protocols = useStorage('protocols', [])
  const procedures = useState('procedures', () => null)

  async function setProtocol(protocolId, body) {
    const options = {
      method: 'PATCH',
      body
    }

    await $api(`/api/protocol/${protocolId}/`, options)
  }

  async function setAlerts(protocolId, val) {
    const { getSession } = useAuth()
    try {
      await setProtocol(protocolId, { alerts: val})
      getSession()
      return true
    } catch (error) {
      console.log("🚀 ~ useProtocol.js ~ setAlerts ~ error:", error)
      return false
    }
  }

  async function setService(protocolId, service) {
    const { getSession } = useAuth()
    try {
      await setProtocol(protocolId, { service })
      getSession()
      return true
    } catch (error) {
      console.log("🚀 ~ useProtocol.js ~ setService ~ error:", error)
      return false
    }
  }

  async function setQuote(quoteId, body) {
    try {
      const options = {
        method: 'PATCH',
        body
      }

      await $api(`/api/quote/${quoteId}/`, options)

      useAuth().getSession()
      return true
    } catch (error) {
      if (error.data) {
        return error.data.user_link[0]
      } else {
        console.log("🚀 ~ useProtocol ~ setQuote ~ error:", error.value)
        return false
      }
    }
  }

  async function getProcedures() {
    try {
      const resProc = await $api('/api/procedure-country-city/')

      for (let i = 0; i < resProc.length; i++) {
        const services = await $api(`/api/procedure-services/${resProc[i].slug}/?page_size=999999`)
        resProc[i].services = services.results
        resProc[i].id = i+1
        resProc[i].image = resProc[i].cover?.img || '/placeholder/procedure.svg'
        resProc[i].imageStyle = resProc[i].cover?.img ? '' : 'p-2.5 bg-label'
      }
      procedures.value = resProc
    } catch (error) {
      console.log("🚀 ~ getProcedures ~ error:", error)
    }
  }

  return {
    protocolDeleteDialog,
    protocol,
    protocols,
    procedures,
    setAlerts,
    setService,
    setQuote,
    getProcedures,
  }
}

export default useProtocol