const useMessage = () => {
  const { $api } = useNuxtApp()
  const { currentRoute } = useRouter()
  const { data: userData } = useAuth()
  const channels = useState('channels', () => [])
  const channelUsers = useState('channelUsers', () => [])
  const selectedChannel = useState('selectedChannel', () => null)
  const messages = useState('messages', () => [])
  const lastSeen = useState('lastSeen', () => null)
  const messagePoll = useState('messagePoll', () => [])
  const hasNewMessage = useState('hasNewMessage', () => false)
  const newMessageCount = useState('newMessageCount', () => 0)
  const showMessageNav = useState('showMessageNav', () => false)
  const showChatDrawer = useState('showChatDrawer', () => false)

  async function getUsers() {
    try {
      const data = await $api('/api/messaging/channels/users')

      if (data) {
        channelUsers.value = data
      }
    } catch (error) {
      if (error.response) {
        console.log("🚀 ~ getUsers ~ error.response:", error.response)
        throw createError({
          statusCode: error.response.status,
          statusMessage: 'Could not fetch users for messages.',
        })
      } else {
        console.log("🚀 ~ getUsers ~ error:", error)
      }
    }
  }

  async function getChannels() {
    try {
      const data = await $api('/api/messaging/channels/')

      if (data) {
        channels.value = data.map(ch => ({...ch, newMessages: 0}))
      }
    } catch (error) {
      if (error.response) {
        console.log("🚀 ~ getChannels ~ error.response:", error.response)
        throw createError({
          statusCode: error.response.status,
          statusMessage: 'Could not fetch message channels.',
        })
      } else {
        console.log("🚀 ~ getChannels ~ error:", error)
      }
    }
  }

  async function startChannelAdmin(user) {
    try {
      const newChannel = await $api('/api/messaging/channels/start/', {
        method: 'POST',
        body: { user }
      })
      selectedChannel.value = newChannel
      channels.value.unshift(newChannel)
    } catch (error) {
      if (error.response) {
        throw createError({
          statusCode: error.response.status,
          statusMessage: 'Could not start channel',
        })
      } else {
        console.log("🚀 ~ startChannel ~ error:", error)
      }
    }
  }

  async function startChannelUser() {
    try {
      const newChannel = await $api('/api/messaging/channels/start_user/', { method: 'POST' })
      selectedChannel.value = newChannel
      return ''
    } catch (error) {
      if (import.meta.dev) {
        console.log("🚀 ~ startChannel ~ error.response:", error)
      }
      console.log("🚀 ~ startChannel ~ error:", error)
      return error.response._data.detail || 'Error creating channel. Please try again.'
    }
  }

  async function sendMessage(message, file = null, fileMeta = null) {
    try {
      let body = { message }
      let method = 'POST'
      let url = `/api/messaging/channels/${selectedChannel.value.id}/send/`

      if (file) {
        method = 'PUT'
        url = `/api/messaging/channels/${selectedChannel.value.id}/upload/`
        const formData = new FormData()
        formData.append('message', message)
        formData.append('name', fileMeta.name)
        formData.append('meta', JSON.stringify(fileMeta))
        formData.append('file', file)
        body = formData
      }

      const options = {
        method,
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        body
      }

      await $api(url, options)
      return ''
    } catch (error) {
      if (error.response) {
        console.log("🚀 ~ sendMessage ~ error.response:", error.response)
        return error?.data?.message
          ? error.data.message[0]
          : 'Error sending your message. Please save your message and try again.'
      } else {
        console.log("🚀 ~ sendMessage ~ error:", error)
        return 'Error sending your message. Please save your message and try again.'
      }
    }
  }

  async function getMessages() {
    try {
      if (!selectedChannel.value) return

      const { data } = useAuth()
      const msgData = await $api(`/api/messaging/channels/${selectedChannel.value.id}/messages/?page_size=999999`)

      if (msgData && msgData?.count > 0) {
        let prevSeen = false
        messages.value = msgData.results.map(message => {
          if (message.seen && message.author_id === data.value?.user.user_id) {
            prevSeen = message.created
          }

          return {
            ...message,
            self: message.author_id === data.value?.user.user_id,
            user: selectedChannel.value.members.find(user => user.user_id === message.author_id),
          }
        })

        lastSeen.value = prevSeen

        if (currentRoute.value.name === 'messages') {
          await seenChannel()
          selectedChannel.value.newMessages = 0
        }
      } else {
        messages.value = []
      }
    } catch (error) {
      if (error.response) {
        console.log("🚀 ~ getMessages ~ error.response:", error.response)
        throw createError({
          statusCode: error.response.status,
          statusMessage: `Could not fetch messages of channel: ${selectedChannel.value.id}`,
        })
      } else {
        console.log("🚀 ~ getMessages ~ error:", error)
      }
    }
  }

  async function pollMessages() {
    try {
      const pollData = await $api('/api/messaging/channels/poll/')
      const isAdmin = adminRoles.includes(userData.value?.user?.ui_role)

      if (pollData && pollData.length > 0) {
        if (pollData.length !== channels.value.length) {
          await getChannels()
          if (!selectedChannel.value && !isAdmin) {
            selectedChannel.value = channels.value.length > 0 ? channels.value[0] : null
          }
        }

        hasNewMessage.value = pollData.some(ch => ch.new_messages > 0)

        if (hasNewMessage.value) {
          newMessageCount.value = pollData.reduce((acc, ch) => acc + ch.new_messages, 0)
          channels.value.forEach((ch,i) => {
            const pollItem = pollData.find(pch => pch.id === ch.id)
            if (pollItem) {
              channels.value[i].newMessages = pollItem.new_messages
            }
          })
        } else {
          newMessageCount.value = 0
        }

        if (hasNewMessage.value && currentRoute.value.name === 'messaging') {
          if (isAdmin) await getChannels()
          await getMessages()
        }

        messagePoll.value = pollData
      } else {
        messagePoll.value = []
      }
    } catch (error) {
      if (error.response) {
        console.log("🚀 ~ getMessages ~ error.response:", error.response)
        throw createError({
          statusCode: error.response.status,
          statusMessage: `Could not fetch messages of channel: ${selectedChannel.value.id}`,
        })
      } else {
        console.log("🚀 ~ getMessages ~ error:", error)
      }
    }
  }

  async function seenChannel() {
    try {
      await $api(`/api/messaging/channels/${selectedChannel.value.id}/seen/`, {
        method: 'PUT',
      })
      return true
    } catch (error) {
      if (error.response) {
        console.log("🚀 ~ seenChannel ~ error.response:", error.response)
        throw createError({
          statusCode: error.response.status,
          statusMessage: 'Could not seen channel',
        })
      } else {
        console.log("🚀 ~ seenChannel ~ error:", error)
      }
      return false
    }
  }

  return {
    channels,
    channelUsers,
    selectedChannel,
    messages,
    lastSeen,
    messagePoll,
    hasNewMessage,
    newMessageCount,
    showMessageNav,
    showChatDrawer,
    getUsers,
    getChannels,
    startChannelAdmin,
    startChannelUser,
    sendMessage,
    getMessages,
    pollMessages,
    seenChannel,
  }
}

export default useMessage